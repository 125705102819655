import {computedFrom, InlineViewStrategy, LogManager} from 'aurelia-framework';
import {FormField} from './form-field';
import * as _ from "lodash";
import '../../array/array-move';
import {PLATFORM} from "aurelia-pal";

const logger = LogManager.getLogger('FormStructure');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

export class FormStructure
{
    contextObjectRef;
    formService;
    parent;

    type = 'form';

    errors = [];

    addRemoveCallback;

    constructor(formService, config, path, value = null, parent = null, contextObjectRef = null)
    {
        this.fullProperty = path;
        this.formService = formService;
        this.parent = parent;
        this.contextObjectRef = contextObjectRef;

        this.setConfig(config, value);
    }

    setConfig(config, value)
    {
        if (!config.fields) {
            config.fields = [];
        }

        //Todo update

        if (config.type == 'collection') {
            _.each(value, () => {
                let entry = _.cloneDeep(config.entry);

                entry.property = config.fields.length;
                entry = this._addFormField(entry, null);

                config.fields.push(entry);
            });
        }

        config.fields.forEach((element, index) => {

            let subValue = null;

            // @fixme FormStructure must not manipulate assigned data
            // @see FormService and @see src/form/loader/data-loader.js::_addDefaultValuesToData

            if (value && _.has(value, element.property)) {
                subValue = _.get(value, element.property);
            } else if (element.default != null) {
                subValue = element.default;
            }

            if (!subValue && element.context && this.contextObjectRef != null &&
                (
                    ((element.modelId) && element.modelId === this.contextObjectRef.modelId) ||
                    (element.implementations && element.implementations.includes(this.contextObjectRef.modelId))
                )
            ) {
                if (element.multiple === true) {
                    subValue = this.contextObjectRef ? [this.contextObjectRef] : [];
                } else {
                    subValue = this.contextObjectRef;
                }
                element.hidden = true;
            }

            config.fields[index] = this._addFormField(element, subValue);
        });

        Object.assign(this, config);

        this.view = this._getView();
        this.viewModel = this._getViewModel();
    }

    getValue()
    {
        logger.info('sortable get value fields', this);

        let object = this.type == 'collection' ? [] : {};

        this.fields.forEach((element, index) => {
            if (null == element.property) {
                return true;
            }

            if (this.type == 'collection') {
                object.push(element.getValue());
            } else {
                object[element.property] = element.getValue();
            }
        });

        return object;
    }

    setValue(value)
    {
        if (!value) {
            return;
        }

        if (this.type == 'collection') {

            _.each(value, (element, index) => {

                if (this.fields.length <= index) {
                    this.add(null, true);
                }

                let field = this.fields[index];

                field.setValue(element);
            });

            if (value.length < this.fields.length) {
                this.fields.splice(value.length, this.fields.length - value.length);
            }

            this._notifyFormServiceAboutChange();

        } else {
            _.forIn(value, (element, key) => {

                try {
                    let field = this._getFieldForKey(key);

                    field.setValue(element);
                } catch (e) {
                    logger.info('Could not find field ' + key);
                }
            });
        }
    }

    _notifyFormServiceAboutChange()
    {
        if (this.formService && this.formService.changeCallback) {
            logger.debug('notifyFormServiceAboutChange', this);
            this.formService.changeCallback(this);
        }
    }

    resetValue()
    {
        if (this.type === 'collection') {
            this.fields = [];
            return;
        }

        this.fields.forEach((element, index) => {
            element.resetValue();
        });
    }

    @computedFrom('errors', 'fields')
    get valid()
    {
        if (0 !== this.errors.length) {
            return false;
        }

        let valid = true;

        _.forEach(this.fields, field => {
            if (!field.valid) {
                valid = false;
                return false;
            }
        });

        return valid;
    }

    resetErrors(silent = false)
    {
        this.errors = [];

        _.forEach(this.fields, field => {
            field.resetErrors(silent);
        });
    }

    setErrors(errors, silent = false)
    {
        this.errors = errors.errors && errors.errors.length > 0 ? errors.errors : [];

        logger.debug('Set errors', this, errors.children);

        if (errors.children) {
            _.forEach(errors.children, (element, key) => {
                try {
                    let field = this._getFieldForKey(key);
                    field.setErrors(element, silent);
                } catch (e) {

                }
            });
        }
    }

    _getFieldForKey(key)
    {
        if (this.type == 'collection') {
            return this.fields[key];
        } else {
            for (let element of this.fields) {
                if (element.property === key) {
                    return element;
                }
            }
            throw new Error('Field not found ' + key);
        }
    }

    _addFormField(element, subValue)
    {
        if ('form' === element.type || 'collection' === element.type || !element.type) {
            return new FormStructure(
                this.formService,
                element,
                ('' !== this.fullProperty ? this.fullProperty + '.' : '') + element.property,
                subValue,
                this,
                this.contextObjectRef
            );
        } else {
            return new FormField(
                this.formService,
                element,
                ('' !== this.fullProperty ? this.fullProperty + '.' : '') + element.property,
                subValue,
                this
            );
        }
    }

    add(value = null, silent = false, index = null)
    {
        if (('collection' !== this.type && 'collection' !== this.subType) || !this.allowAdd || !this.entry) {
            throw new Error('This method should not be called for collection types');
        }

        let entry = _.cloneDeep(this.entry);

        entry.property = this.fields.length;
        entry = this._addFormField(entry, value);

        if (index) {
            this.fields.splice(index, 0, entry);
        } else {
            this.fields.push(entry);
        }

        if (!silent) {
            this._notifyFormServiceAboutChange();
        }

        if (this.addRemoveCallback) {
            this.addRemoveCallback();
        }

        console.debug('fields', this.fields);
    }

    remove(index, silent = false)
    {
        if (('collection' !== this.type && 'collection' !== this.subType) || !this.allowDelete) {
            throw new Error('Not allowed to remove entries');
        }

        this.fields.splice(index, 1);

        if (!silent) {
            this._notifyFormServiceAboutChange();
        }

        if (this.addRemoveCallback) {
            this.addRemoveCallback();
        }
    }

    shouldShowField(field)
    {
        if (!field || field.hidden) {
            return false;
        }

        if (!this.formService || !this.formService.conditionMatcher) {
            return true;
        }

        if (!field.showIf || field.showIf.length === 0) {
            return true;
        }

        return this.formService.conditionMatcher.matchConditions(this.getValue(), field.showIf);
    }

    move(index, toIndex)
    {
        this.fields.move(index, toIndex);

        // form collection special case: rename property names
        if ('form' === this.type && 'collection' === this.subType) {
            for (let i = 0; i < this.fields.length; i++) {
                this.fields[i].property = i;
                this.fields[i].fullProperty = ('' !== this.fullProperty ? this.fullProperty + '.' : '') + i;
            }
        }

        if (this.addRemoveCallback) {
            this.addRemoveCallback();
        }
    }

    _getViewModel()
    {
        if (this.layout) {
            return 'form/structure/' + this.layout;
        }

        return PLATFORM.moduleName('form/form-layout');
    }

    _getView()
    {
        if (this.template) {
            return new InlineViewStrategy('<template>' + this.template + '</template>');
        }

        if (this.layout) {
            return 'form/structure/' + this.layout  + '.html';
        }

        return PLATFORM.moduleName('form/default-form-layout.html');
    }

    getFieldByProperty(property) {
        return _.find(this.fields, (field) => field.property === property);
    }
}

PLATFORM.moduleName('form/structure/sortable-days-collection');
PLATFORM.moduleName('form/structure/sortable-form-collection');
PLATFORM.moduleName('form/structure/sortable-single-field');
PLATFORM.moduleName('form/structure/sortable-timetable-collection');
PLATFORM.moduleName('form/structure/sortable-child-reduction-collection');
