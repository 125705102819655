import {useContext} from "react";
import AureliaContext from "../../utilities/aurelia-context";
import Panel from "../../view-block/panel/react-panel";
import {Table} from "rsuite";

export function Margin({calculatedCosts, realCosts, income, realMargin}) {
    const {currencyValueConverter, i18n} = useContext(AureliaContext);

    const rows = []

    if (calculatedCosts?.amount) {
        rows.push({
            key: "kalkuliert",
            costs: calculatedCosts
        })
    }

    if (realCosts?.amount) {
        rows.push({
            key: "real",
            costs: realCosts
        })
    }

    return (
        <Panel heading="costs.margin">
            <Table autoHeight rowKey="key" data={rows}>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell/>
                    <Table.Cell dataKey="key"/>
                </Table.Column>

                <Table.Column flexGrow={1} align="right">
                    <Table.HeaderCell>{i18n.tr("costs.income")}</Table.HeaderCell>
                    <Table.Cell>
                        {currencyValueConverter.toView(income)}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} align="right">
                    <Table.HeaderCell>{i18n.tr("costs.costs")}</Table.HeaderCell>
                    <Table.Cell>
                        {({costs}) => currencyValueConverter.toView(costs)}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} align="right">
                    <Table.HeaderCell>{i18n.tr("costs.margin")}</Table.HeaderCell>
                    <Table.Cell>
                        {({costs}) => currencyValueConverter.toView({
                            amount: income.amount - costs.amount,
                            currency: income.currency
                        })}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </Panel>
    )
}
