import {inject} from 'aurelia-framework';
import {AuthService} from '../auth/auth-service';
import {Router} from 'aurelia-router';
import {OAuthClient} from "../api/oauth-client";
import {UserClient} from "../api/user-client";
import './login.less';
import {AureliaConfiguration} from "aurelia-configuration";
import Client from "../api/client";

@inject(
    AuthService,
    Router,
    OAuthClient,
    Client,
    UserClient,
    AureliaConfiguration
)
export class Login {

    image = require('./login.png');

    date = new Date();

    configuration = {
        submitBtnClass: 'btn btn-primary btn-block',
        actionContainerClass: ' ',
        labels: {
            success: 'user.login_success',
            communicationError: 'user.error_login',
            submit: 'login.login',
        },
        fields: [
            {
                type: 'input',
                label: 'login.username',
                property: 'login',
                required: true,
                constraints: true
            },
            {
                type: 'input',
                subType: 'password',
                label: 'login.password',
                property: 'password',
                required: true
            }
        ]
    };

    oauthProviders = [];

    constructor(
        authService,
        router,
        oauthClient,
        client,
        userClient,
        appConfig
    ) {
        this.authService = authService;
        this.router = router;
        this.oauthClient = oauthClient;
        this.client = client;
        this.userClient = userClient;

        this.apiUrl = appConfig.get('apiUrl');
    }

    attached()
    {
        this.client.get('oauth2/active-providers', 3600, {credentials: 'omit'}).then((data) => {
            this.oauthProviders = data.providers ?? [];
            this.disablePasswordLogin = data.disablePasswordLogin ?? false;
        }).catch(e => {

        });
    }

    toReset()
    {
        //Note: router does not work here because of some router magic we do with setRoot()
        window.location.href = '/login/request-reset';
    }

    async submit(object)
    {
        let response = await this.oauthClient.getToken(object.login, object.password);

        await this.userClient.load();

        this.authService.login(response);
    }

    loginWithService(service)
    {
        window.location.href = this.apiUrl + 'oauth2/login-with-service/' + encodeURIComponent(service) +
            '?redirect=' + encodeURIComponent(window.location.href);
    }
}
