import { noView, inject, LogManager } from "aurelia-framework";
import { SortableFormLayout } from "./sortable-form-collection";
import { BindingSignaler } from "aurelia-templating-resources";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import { Client } from "../../api/client";
import { DialogService } from "aurelia-dialog";
import {UserClient} from "../../api/user-client"
import moment from "moment-timezone";

const logger = LogManager.getLogger("SortableDaysCollection");
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@noView
@inject(BindingSignaler, EventAggregator, Client, I18N, DialogService, UserClient)
export class SortableDaysCollection extends SortableFormLayout {
    constructor(signaler, ea, client, i18n, DialogService, userClient) {
        super(signaler, ea, client, i18n, DialogService);
        this.userClient = userClient;
    }

    _getControlUID() {
        return "sortable-days-collection-" + this.config.fullProperty;
    }

    getTabTitle(fieldToFind) {
        const index = this.getFieldIndex(fieldToFind, this.fields);
        let title = fieldToFind.getFieldByProperty("title")?.value;

        if (
            title &&
            typeof title === "object" &&
            Object.keys(title).length > 0
        ) {
            title = title[Object.keys(title)[0]];
        }

       // let showAccommodationInOneDay = this.userClient.getUser().organization.settings.tourismOrderSettings.showAccommodationInOneDay;
        let days = this.config.formService.getValue().days;
        let prefix = this._getTabPrefix(index);
        let endingDefaultDateIndex = index;

         if (days[index] /* && showAccommodationInOneDay*/) {
            if (days[index].accommodation) {
                prefix += ' (' +moment(days[index].startDate).format('DD.MM.') + '-' + moment(days[index].endDate).format('DD.MM.') + ') ';
            } else {
                if(!days[index].endDate){
                    let endDate = moment(days[index-1].endDate).add(1,'days');
                    prefix += ' (' + endDate.format('DD.MM.');
                    this._getDaysDifference(endingDefaultDateIndex) ? prefix += '-' + this._getDaysDifference(endingDefaultDateIndex) + ') ':null;
                }else{
                    prefix += ' (' +moment(days[index].startDate).format('DD.MM.') + '-' + moment(days[index].endDate).format('DD.MM.') + ') ';
                }
            }
        }
        return `<span class="days-count"> ${prefix} | </span>${title ? title : "<Neu>"}`;
    }

    _getTabPrefix(index) {
        let lastRealWorldDay = 1;
        let days = this.config.formService.getValue().days;

        days.forEach((day) => {
            if (day.realWorldDuration == null) {
                day.realWorldDuration = 1;
            }

            if (Number(day.realWorldDuration) === 1) {
                day.realWorldInterval = lastRealWorldDay;
            } else {
                const startingDay = Number(lastRealWorldDay);
                const endingDay = Number(startingDay) + Number(day.realWorldDuration) - 1;

                day.realWorldInterval = startingDay + "-" + endingDay;
            }

            lastRealWorldDay += Number(day.realWorldDuration);
        });

        logger.debug("Days", days);
        console.log("Days", days);

        if (null == days[index]) {
            return "";
        }
        return days[index].realWorldInterval;
    }

    _getDaysDifference(index){
        let days = this.config.formService.getValue().days;
        let currentDate = moment(days[index-1].endDate);
        let endDateDuration = days[index].realWorldDuration;
        let newDate = currentDate.add(endDateDuration, 'days');
        let formattedNewDate = newDate.format('DD.MM.'); 
        return formattedNewDate
    }
}
